<template>
  <div class="Client font">
    <div class="app-content content">
      <div class="content-overlay"></div>
      <div class="header-navbar-shadow"></div>
      <div
        class="content-wrapper container-xxl p-0 font"
        v-if="permissionCheck.viewAny == false"
      >
        <div class="p-2">
          <img style="width: 100%" src="/assets/image/accessDenide.png" alt="" />
        </div>
      </div>
      <div class="content-wrapper container-xxl p-0 font" v-else>
        <banner
          title="retailers"
          :breadcrumb="[
            {
              label: 'Dashboard',
            },
            { label: 'Retailers' },
          ]"
        >
        </banner>

        <div v-if="loading == true">
          <Spinner />
        </div>
        <div class="row font" style="margin-top: -45px">
          <div class="col-xl-12 col-md-12 col-sm-12 col-12">
            <div class="d-xl-none d-md-none d-block pt-1"></div>
            <div class="card custom-card">
              <div class="card-body">
                <div class="row">
                  <div class="col text font">

                    <h4><b style="color: #00364f; font-weight: bolder">Retailers</b></h4>
                  </div>
                  <div class="col text-end ">
                    <button type="button" @click="loadFormNull()" data-bs-toggle="modal"
                      data-bs-target="#masterDistributerModal" title="Add New" style="background-color: #f21000"
                      class="btn text-white btn-sm">+ New</button>
                    &nbsp;<button type="button" data-bs-toggle="modal" data-bs-target="#new-task-modal" title="Filter"
                      class="btn text-white btn-sm" style="background-color: #00364f"> <strong> <font-awesome-icon
                          icon="filter" /></strong></button>

                  </div>
                  <div class="col-xl-1 col-md-1 d-md-block d-xl-block d-none cus-p-1" style="margin-top:-5px">

                    <select style="height: 30px; padding: 5px 5px 5px 5px" class="form-select" @change="changePageNo"
                      aria-label="Default select example">

                      <option value="10">10</option>
                      <option value="25">25</option>
                      <option value="50">50</option>
                      <option value="100">100</option>
                    </select>
                  </div>
                </div>



                <div class="row">
                  <div class="d-md-none d-xl-none d-block col-3 text font">

                    <select style="height: 30px; padding: 5px 5px 5px 5px" class="form-select" @change="changePageNo"
                      aria-label="Default select example">
                      <option value="10">10</option>
                      <option value="25">25</option>
                      <option value="50">50</option>
                      <option value="100">100</option>

                    </select>
                  </div>

                </div>

                <!-- start list area  -->
                <div class="row" style="margin-top:5px">
                

                  <div
                    class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"
              
                  >
                    <div class="container-fluid table-scroll" style="margin-left: -5px">
                      <table class="table table-hover table-sm font">
                        <tr class="text tr-head table-wrapper-scroll-y rounded-circle">
                          <th class="text-truncate">&nbsp;&nbsp;+&nbsp;&nbsp;</th>
                          <th class="text-truncate">Code</th>
                          <th class="text-truncate">Name</th>
                          <th class="text-truncate">Admin</th>
                          <th class="text-truncate">Mobile No.</th>
                          <th class="text-truncate">Email Id</th>
                          <!-- <th>Services</th> -->
                          <th class="text-truncate">Kyc </th>
                          <th class="text-truncate">Login Status</th>
                          <th class="text-truncate">Action</th>
                        </tr>

                        <tbody>
                          <tr
                            style="color: #00364f"
                            v-for="(retailerinfo, index) in retailers"
                            :key="index"
                          >
                            <td>
                              <img
                                style="width: 30px; height: 30px; border-radius: 2px"
                                :src="retailerlogofinder(retailerinfo)"
                                alt=""
                              />
                            </td>
                            <td v-if="retailerinfo">{{ retailerinfo.code }}</td>
                            <td class="text-truncate" v-if="retailerinfo">
                              {{ retailerinfo.name }}
                            </td>
                            <td  v-if="retailerinfo.admin">
                              <span v-if="retailerinfo.admin" class="text-truncate">
                                <strong>{{ retailerinfo.admin.orgnization }}</strong></span
                              >
                              <br />
                              <span v-if="retailerinfo.admin" class="text-truncate"
                                ><small
                                  >{{ retailerinfo.admin.code }} : {{ retailerinfo.admin.name }} (
                                    <span v-if="retailerinfo.admin.portal == 'true'">Platform  </span>
                                    <span v-else>Franchise   </span>
                                  )</small
                                >
                              </span>
                            </td>
                           
                            <td v-if="retailerinfo">{{ retailerinfo.phone }}</td>
                            <td v-if="retailerinfo">{{ retailerinfo.email }}</td>

                            <td style="padding: 0px 15px 0px 15px" class="text-truncate">
                              <span v-if="retailerinfo.retailer_kyc">
                                <p
                                  v-if="
                                    retailerinfo.retailer_kyc.kycStatus ==
                                    'approve'
                                  "
                                  class="text-white btn btn-sm"
                                  style="
                                    background-color: green;
                                    width: 70px;
                                    box-shadow: 2px 2px 5px gray;
                                    border-radius: 50px;
                                    font-size: 10px;
                                    padding-top: 5px;
                                    padding-bottom: 5px;
                                    padding-left: 7px;
                                    padding-right: 7px;
                                    margin-top: 5px;
                                    margin-bottom: 5px;
                                  "
                                >
                                  <b>
                                    {{
                                      retailerinfo.retailer_kyc.kycStatus
                                    }}</b
                                  >
                                </p>

                                <p
                                  v-if="
                                    retailerinfo.retailer_kyc.kycStatus ==
                                    'cancel'
                                  "
                                  class="text-white btn btn-sm"
                                  style="
                                    background-color: #f21000;
                                    width: 70px;
                                    box-shadow: 2px 2px 5px gray;
                                    border-radius: 50px;
                                    font-size: 10px;
                                    padding-top: 5px;
                                    padding-bottom: 5px;
                                    padding-left: 7px;
                                    padding-right: 7px;
                                    margin-top: 5px;
                                    margin-bottom: 5px;
                                  "
                                >
                                  <b>
                                    {{
                                      retailerinfo.retailer_kyc.kycStatus
                                    }}</b
                                  >
                                </p>

                                <p
                                  v-if="
                                    retailerinfo.retailer_kyc.kycStatus ==
                                    'reject'
                                  "
                                  class="text-white btn btn-sm"
                                  style="
                                    background-color: black;
                                    width: 70px;
                                    box-shadow: 2px 2px 5px gray;
                                    border-radius: 50px;
                                    font-size: 10px;
                                    padding-top: 5px;
                                    padding-bottom: 5px;
                                    padding-left: 7px;
                                    padding-right: 7px;
                                    margin-top: 5px;
                                    margin-bottom: 5px;
                                  "
                                >
                                  <b>
                                    {{
                                      retailerinfo.retailer_kyc.kycStatus
                                    }}</b
                                  >
                                </p>

                                <p
                                  v-if="
                                    retailerinfo.retailer_kyc.kycStatus ==
                                    'false'
                                  "
                                  class="text-white btn btn-sm"
                                  style="
                                    background-color: rgb(235, 133, 16);
                                    width: 70px;
                                    box-shadow: 2px 2px 5px gray;
                                    border-radius: 50px;
                                    font-size: 10px;
                                    padding-top: 5px;
                                    padding-bottom: 5px;
                                    padding-left: 7px;
                                    padding-right: 7px;
                                    margin-top: 5px;
                                    margin-bottom: 5px;
                                  "
                                >
                                <b>Pending</b
                                  >
                                </p>
                              </span>
                              <span v-else> 
                                <p
                                  class="text-white btn btn-sm"
                                  style="
                                    background-color: rgb(235, 133, 16);
                                    width: 70px;
                                    box-shadow: 2px 2px 5px gray;
                                    border-radius: 50px;
                                    font-size: 10px;
                                    padding-top: 5px;
                                    padding-bottom: 5px;
                                    padding-left: 7px;
                                    padding-right: 7px;
                                    margin-top: 5px;
                                    margin-bottom: 5px;
                                  "
                                >
                                  <b>Pending</b
                                  >
                                </p>
                              </span>
                            </td>
                            <td>
                              <p
                                v-if="retailerinfo.loginStatus == 'true'"
                                class="text-white btn btn-sm"
                                style="
                                  background-color: green;
                                  width: 70px;
                                  box-shadow: 2px 2px 5px gray;
                                  border-radius: 50px;
                                  font-size: 10px;
                                  padding-top: 5px;
                                  padding-bottom: 5px;
                                  padding-left: 7px;
                                  padding-right: 7px;
                                  margin-top: 5px;
                                  margin-bottom: 5px;
                                "
                              >
                                <b> Active</b>
                              </p>
                              <p
                                v-else
                                class="text-white btn btn-sm"
                                style="
                                  background-color: red;
                                  width: 70px;
                                  box-shadow: 2px 2px 5px gray;
                                  border-radius: 50px;
                                  font-size: 10px;
                                  padding-top: 5px;
                                  padding-bottom: 5px;
                                  padding-left: 7px;
                                  padding-right: 7px;
                                  margin-top: 5px;
                                  margin-bottom: 5px;
                                "
                              >
                                <b> InActive</b>
                              </p>
                            </td>
                            <td class="text-end">
                              <div class="btn-group btn-group-sm" role="group"  v-if="retailerinfo.admin">
                                <router-link
                                  title="View"
                                  v-if="permissionCheck.view != false"
                                  :to="`retailer-profile-view/${retailerinfo.id}`"
                                  class="btn btns btn-sm text-white"
                                  style="
                                    padding: 5px 4.5px 5px 4.5px;
                                    background-color: #00364f;
                                  "
                                >
                                  <font-awesome-icon icon="eye" />
                                </router-link>
                                <router-link
                                v-if="retailerinfo.admin.portal == 'false'"
                                  title="View"
                                  :to="`retailer-transections-staff/${retailerinfo.id}`"
                                  class="btn btns btn-sm text-white"
                                  style="
                                    padding: 5px 6px 5px 6px;
                                    background-color: #f21000;
                                  "
                                >
                                  ₹
                                </router-link>
                                <router-link
                                  title="TDS"
                                  :to="`retailer-tds/${retailerinfo.id}`"
                                  class="btn btns btn-sm text-white"
                                  style="
                                    padding: 5px 4.5px 5px 4.5px;
                                    background-color: 730087;
                                  "
                                >
                                <i class="fa fa-file-text-o" aria-hidden="true"></i></router-link>
                                <router-link
                                  title="Kyc"
                                  to="#"
                                  @click="retailerkycValueAsign(retailerinfo)"
                                  v-if="permissionCheck.kycView != false"
                                  data-bs-toggle="modal"
                                  data-bs-target="#kycModal"
                                  class="btn btns btn-sm text-dark"
                                  style="
                                    padding: 5px 4.5px 5px 4.5px;
                                    background-color: yellow;
                                  "
                                >
                                  <font-awesome-icon icon="file" />
                                </router-link>

                                <button
                                    title="Verify"
                                    v-if="retailerinfo.retailer_kyc != null"
                                    @click="retailerkycValueApprove(retailerinfo)"
                                    class="btn btns btn-sm"
                                    style="
                                      width: 27px;
                                      padding: 5px 4.5px 5px 4.5px;
                                      background-color: #1426f5;
                                      color: white;
                                    "
                                    data-bs-toggle="modal"
                                    data-bs-target="#approoveKycModal"
                                  >
                                    <b>
                                      <font-awesome-icon icon="tag" />
                                    </b>
                                  </button>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <!-- end list area -->

                <div class="card-footer">
                <div class="row">

                  <div class="col-md-4 col-12 text-md-start text-center cus-p-1">

                    <p class="mb-0">
                      Showing {{ pagination.from }} to {{ pagination.to }} of {{ pagination.total }} entries
                    </p>
                  </div>
                  <div class="col-md-8 col-12 text-md-end text-center cus-p-1">
                    <ul class="pagination  justify-content-center">
                      <li class="page-item" @click="loadretailers(null, 'first')">
                        <a class="page-link" href="#" aria-label="Previous">
                          <span aria-hidden="true">&laquo;</span>
                        </a>
                      </li>
                      <li class="page-item" @click="loadretailers(null, 'prev')">
                        <a class="page-link" href="#">Previous</a>
                      </li>
                      <li class="page-item" v-if="pagination.current_page > 3"
                        @click="loadretailers(pagination.current_page - 3)">
                        <a class="page-link" href="#">{{
                          pagination.current_page - 3
                        }}</a>
                      </li>
                      <li class="page-item" v-if="pagination.current_page > 2"
                        @click="loadretailers(pagination.current_page - 2)">
                        <a class="page-link" href="#">{{
                          pagination.current_page - 2
                        }}</a>
                      </li>
                      <li class="page-item" v-if="pagination.current_page > 1"
                        @click="loadretailers(pagination.current_page - 1)">
                        <a class="page-link" href="#">{{
                          pagination.current_page - 1
                        }}</a>
                      </li>
                      <li class="active page-item" @click="loadretailers(pagination.current_page)">
                        <a class="page-link" href="#">{{ pagination.current_page }}</a>
                      </li>
                      <li class="page-item" v-if="pagination.current_page < pagination.last_page - 4"
                        @click="loadretailers(pagination.current_page + 1)">
                        <a class="page-link" href="#">{{
                          pagination.current_page + 1
                        }}</a>
                      </li>
                      <li class="page-item" v-if="pagination.current_page < pagination.last_page - 5"
                        @click="loadretailers(pagination.current_page + 2)">
                        <a class="page-link" href="#">{{
                          pagination.current_page + 2
                        }}</a>
                      </li>
                      <li class="page-item" v-if="pagination.current_page < pagination.last_page - 6"
                        @click="loadretailers(pagination.current_page + 3)">
                        <a class="page-link" href="#">{{
                          pagination.current_page + 3
                        }}</a>
                      </li>
                      <!-- <li v-if="pagination.current_page < (pagination.last_page - 4)" @click="loadretailers( pagination.current_page + 4 )"><a href="#">{{pagination.current_page + 4}}</a></li> -->
                      <li class="page-item">
                        <a class="page-link" href="#">...</a>
                      </li>

                      <!-- <li v-if="pagination.current_page < (pagination.last_page - 4)" @click="loadretailers( pagination.last_page - 4 )"><a href="#">{{pagination.last_page - 4}}</a></li> -->
                      <li class="page-item" v-if="pagination.current_page < pagination.last_page - 3"
                        @click="loadretailers(pagination.last_page - 3)">
                        <a class="page-link" href="#">{{ pagination.last_page - 3 }}</a>
                      </li>
                      <li class="page-item" v-if="pagination.current_page < pagination.last_page - 2"
                        @click="loadretailers(pagination.last_page - 2)">
                        <a class="page-link" href="#">{{ pagination.last_page - 2 }}</a>
                      </li>
                      <li class="page-item" v-if="pagination.current_page < pagination.last_page - 1"
                        @click="loadretailers(pagination.last_page - 1)">
                        <a class="page-link" href="#">{{ pagination.last_page - 1 }}</a>
                      </li>

                      <li class="page-item" @click="loadretailers(null, 'next')">
                        <a class="page-link" href="#">Next</a>
                      </li>
                      <li class="page-item" @click="loadretailers(null, 'last')">
                        <a class="page-link" href="#">&raquo;</a>
                      </li>
                    </ul>
                  </div>

                  <!-- <div class="col-md-1 col-4 text-end">
      <select
                      style="height: 30px; padding: 5px 5px 5px 5px"
                      class="form-select"
                      @change="changePageNo"
                      aria-label="Default select example"
                    >
         
                      <option value="10">10</option>
                      <option value="25">25</option>
                      <option value="50">50</option>
                      <option value="100">100</option>
                    </select>
    </div> -->
                </div>
              </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- END: Content-->

    <div class="sidenav-overlay"></div>
    <div class="drag-target"></div>
  </div>

  <!-- Modal -->
  <div
    class="modal fade"
    id="kycModal"
    tabindex="-1" data-bs-backdrop="static" data-bs-keyboard="false"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">
            <strong style="color: #00364f">{{ kycModalHeading }}</strong>
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div class="row" v-if="msgImgError">
            <div class="col">
              <div class="alert alert-danger p-1" role="alert">
                {{ msgImgError }}
              </div>
            </div>
          </div>

          <form
            @submit.prevent="
              retailerKycVal == 'false' ? retailerkycCreate() : retailerkycUpdate()
            "
          >
            <table class="table table-hover table-sm" style="font-size: 14px">
              <tr class="text tr-head table-wrapper-scroll-y rounded-circle">
                <th>+</th>
                <th>Documents</th>
                <th
                  class="text-end"
                  v-if="
                    this.permissionCheck.kycCreate != false ||
                    this.permissionCheck.kycUpdate != false
                  "
                >
                  Action
                </th>
              </tr>
              <tbody>
                <tr>
                  <td>
                    <img
                      style="width: 30px; height: 30px"
                      v-if="adharFrontImg"
                      :src="adharFrontImg"
                    />
                  </td>

                  <td class="text-truncate text">
                    <b for="AdharFront">Retailer Aadhaar Front</b>
                    <input
                      @change="uploadAdharFront"
                      class="input-file"
                      style="position: absolute; top: -150000px"
                      id="AdharFront"
                      type="file"
                      accept="image/*"
                    />
                    <input
                      type="number"
                      v-model="form.aadharNo"
                      class="form-control"
                      placeholder="Adhar Number"
                      style="height: 30px"
                     
                      required
                    />
                    <!-- @change="lenthheker($event, 'clientKycAddar')" -->
                    <span style="color: #f21000" id="clientKycAddar"></span>
                    <small style="color: red" v-if="form.errors"
                      ><span v-if="form.errors.error"
                        ><span v-if="form.errors.error.aadharNo">{{
                          form.errors.error.aadharNo[0]
                        }}</span></span
                      ></small
                    >
                  </td>
                  <td
                    class="text-truncate text-end"
                    v-if="
                      this.permissionCheck.kycCreate != false ||
                      this.permissionCheck.kycUpdate != false
                    "
                  >
                    <label for="AdharFront">
                      <img
                        style="width: 25px; height: 25px"
                        src="/assets/image/upload.png"
                      />
                    </label>
                  </td>
                </tr>

                <tr>
                  <td>
                    <img
                      style="width: 30px; height: 30px"
                      v-if="adharBackImg"
                      :src="adharBackImg"
                    />
                  </td>

                  <td class="text-truncate text">
                    <b for="AdharBack">Retailer Aadhaar Back</b>
                    <input
                      @change="uploadAdharBack"
                      class="input-file"
                      style="position: absolute; top: -150000px"
                      id="AdharBack"
                      type="file"
                      accept="image/*"
                    />
                  </td>
                  <td
                    class="text-truncate text-end"
                    v-if="
                      this.permissionCheck.kycCreate != false ||
                      this.permissionCheck.kycUpdate != false
                    "
                  >
                    <label for="AdharBack">
                      <img
                        style="width: 25px; height: 25px"
                        src="/assets/image/upload.png"
                      />
                    </label>
                  </td>
                </tr>

                <tr>
                  <td>
                    <img style="width: 30px; height: 30px" v-if="panImg" :src="panImg" />
                  </td>

                  <td class="text-truncate text">
                    <b for="panphoto">Retailer Pan</b>
                    <input
                      @change="uploadPanPhoto"
                      class="input-file"
                      style="position: absolute; top: -150000px"
                      id="panphoto"
                      type="file"
                      accept="image/*"
                    />
                    <div class="form-group">
                      <!-- <label>Business Pan <span style="color: #f21000">*</span></label> -->
                      <div v-if="panVarifyingStatus == 'true'">
                        <input
                          required
                          disabled
                          type="tel"
                          maxlength="10"
                          v-model="form.panNo"
                          class="form-control"
                          placeholder="Pan Number"
                          style="text-transform: uppercase; height: 30px"
                          @change="businessPanVeryfied($event)"
                          @keydown="nameKeydown($event)"
                        />
                      </div>
                      <div v-if="panVarifyingStatus == 'false'">
                        <input
                          required
                          type="tel"
                          maxlength="10"
                          v-model="form.panNo"
                          class="form-control"
                          placeholder="Pan Number"
                          style="text-transform: uppercase; height: 30px"
                          @change="businessPanVeryfied($event)"
                          @keydown="nameKeydown($event)"
                        />
                      </div>

                      <span style="color: #f21000" id="businessPanVeryfiedAdmin"></span>
                      <small style="color: red" v-if="form.errors"
                        ><span v-if="form.errors.error"
                          ><span v-if="form.errors.error.panNo">{{
                            form.errors.error.panNo[0]
                          }}</span></span
                        ></small
                      >
                    </div>
                  </td>
                  <td
                    class="text-truncate text-end"
                    v-if="
                      this.permissionCheck.kycCreate != false ||
                      this.permissionCheck.kycUpdate != false
                    "
                  >
                    <label for="panphoto">
                      <img
                        style="width: 25px; height: 25px"
                        src="/assets/image/upload.png"
                      />
                    </label>
                  </td>
                </tr>
              </tbody>
            </table>

            <div
              class="modal-footer"
              v-if="
                this.permissionCheck.kycCreate != false ||
                this.permissionCheck.kycUpdate != false
              "
            >
              <button
                type="button"
                class="btn btn-sm text-white"
                style="background-color: #f21300"
                data-bs-dismiss="modal"
              >
                Close
              </button>
              <button
                type="submit"
                class="btn text-white btn-sm"
                style="background-color: #00364f"
              >
                Confirm
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>


    <!-- Modal approoveKycModal-->

    <div
    class="modal fade"
    id="approoveKycModal"
    tabindex="-1" data-bs-backdrop="static" data-bs-keyboard="false"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title" id="exampleModalLabel">
            <strong class="text">Approve Kyc</strong>
          </h4>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div class="container-fluid table-scroll">
            <table class="table table-hover table-sm" style="font-size: 14px">
              <tr class="table-wrapper-scroll-y text tr-head rounded-circle">
                <th>+</th>
                <th>Ducument Name</th>
                <th class="text-end">Download</th>
              </tr>

              <tbody>
                <tr>
                  <td>
                    <img
                      style="width: 50px; height: 50px"
                      v-if="adharFrontImg"
                      :src="adharFrontImg"
                    />
                  </td>

                  <td class="text-truncate text">
                    <b for="AdharFront">Retailer  Aadhaar Front&nbsp;&nbsp;</b>
                    <span>{{ this.aadharNoForKyc }}</span>
                    <select
                      @change="aadharKycApprovedSave()"
                      class="form-select"
                      v-model="aadharapproveKyc.status"
                    >
                      <option value="approve">Approve</option>
                      <!-- <option value="reject">Reject</option> -->
                      <option value="cancel" selected>Cancel</option>
                    </select>
                  </td>
                  <td class="text-truncate text-end">
                    <!-- <label for="AdharFront"> -->
                    <!-- <button
                      @click="
                        extensionkycAdharFront == 'pdf'
                          ? openWindow(adharFrontImgPdf)
                          : openWindow(adharFrontImg)
                      "
                      style="padding: 5px 4.5px 5px 4.5px; background-color: #00364f"
                      class="btn btns text-white btn-sm"
                    >
                      <i class="fa fa-download" aria-hidden="true"></i>
                    </button>
                     -->
                    <button
                      @click="
                        extensionkycAdharFront.toLowerCase() == 'pdf'
                          ? openWindow(adharFrontImgPdf)
                          : openWindow(adharFrontImg)
                      "
                      style="padding: 5px 4.5px 5px 4.5px; background-color: #00364f"
                      class="btn btns text-white btn-sm"
                    >
                      <i class="fa fa-download" aria-hidden="true"></i>
                    </button>
                  </td>
                </tr>

                <tr>
                  <td>
                    <img
                      style="width: 50px; height: 50px"
                      v-if="adharBackImg"
                      :src="adharBackImg"
                    />
                  </td>

                  <td class="text-truncate text">
                    <b for="AdharBack">Retailer  Aadhaar Back</b>
                  </td>
                  <td class="text-truncate text-end">
                    <button
                      @click="
                        extensionkycAdharBack.toLowerCase() == 'pdf'
                          ? openWindow(adharBackImgPdf)
                          : openWindow(adharBackImg)
                      "
                      style="padding: 5px 4.5px 5px 4.5px; background-color: #00364f"
                      class="btn btns text-white btn-sm"
                    >
                      <i class="fa fa-download" aria-hidden="true"></i>
                    </button>
                  </td>
                </tr>

                <tr>
                  <td>
                    <img style="width: 50px; height: 50px" v-if="panImg" :src="panImg" />
                  </td>

                  <td class="text-truncate text">
                    <b for="panphoto">Retailer  Pan &nbsp;&nbsp;</b>
                    <span> {{ this.panNoForKyc }}</span>
                    <select
                    
                      v-model="panapproveKyc.status"
                      @change="panKycApprovedSave()"
                      class="form-select"
                    >
                      <option value="approve">Approve</option>
                      <option value="cancel" selected>Cancel</option>
                    </select>
                  </td>
                  <td class="text-truncate text-end">
                    <button
                      @click="
                        extensionkycPanPhoto.toLowerCase() == 'pdf'
                          ? openWindow(panImgPdf)
                          : openWindow(panImg)
                      "
                      style="padding: 5px 4.5px 5px 4.5px; background-color: #00364f"
                      class="btn btns text-white btn-sm"
                    >
                      <i class="fa fa-download" aria-hidden="true"></i>
                    </button>
                  </td>
                </tr>

                <tr>
                  <td>
                    <!-- <img style="width: 50px; height: 50px" v-if="panImg" :src="panImg" /> -->
                  </td>

                  <td class="text-truncate text">
                    <b for="panphoto">Retailer  Kyc</b>
                    <select required v-model="approveKyc.status" class="form-select">
                      <option value="approve">Approve</option>
                      <option value="cancel" selected>Cancel</option>
                    </select>
                    <br />
                    <textarea
                      v-model="approveKyc.remark"
                      rows="2"
                      class="form-control"
                      placeholder="Remark"
                    ></textarea>
                  </td>
                  <td class="text-truncate text-end">
                    <!-- <button
                      type="button"
                      @click="retailerKycApprovedSave"
                      class="btn btns text-white btn-sm"
                      style="padding: 5px 4.5px 5px 4.5px; background-color: #00364f"
                    >
                      <font-awesome-icon icon="floppy-disk" />
                    </button> -->
                  </td>
                </tr>
              </tbody>
            </table>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-sm text-white"
                style="background-color: #f21300"
                data-bs-dismiss="modal"
              >
                Close
              </button>
              <button
              type="button"
                      @click="retailerKycApprovedSave"
                class="btn text-white btn-sm"
                style="background-color: #00364f"
              >
                Confirm
              </button>
            </div>
            <!-- </form> -->
          </div>
        </div>
      </div>
    </div>
  </div>



  <!-- Right Sidebar starts -->
  <div class="modal modal-slide-in sidebar-todo-modal fade" id="new-task-modal">
    <div class="modal-dialog sidebar-sm">
      <div class="modal-content p-0">
        <form
          id="form-modal-todo"
          class="todo-modal needs-validation"
          novalidate
          onsubmit="return false"
        >
          <div
            class="modal-header align-items-center mb-1"
            style="background-color: #00364f"
          >
            <h5 class="modal-title text-white"><strong>Project Filter</strong></h5>
            <div
              class="todo-item-action d-flex align-items-center justify-content-between ms-auto text-white"
            >
              <span class="todo-item-favorite cursor-pointer me-75 text-white"
                ><i data-feather="star" class="font-medium-2"></i
              ></span>
              <i
                data-feather="x"
                class="cursor-pointer"
                data-bs-dismiss="modal"
                stroke-width="3"
                >X</i
              >
            </div>
          </div>
          <div class="modal-body flex-grow-1 pb-sm-0 pb-3">
            <ul class="list-group list-group-flush">
              <li class="list-group-item">
                <div class="form-check">
                  <input
                    @click="valueNullFilter()"
                    v-model="filterType"
                    value="code"
                    class="form-check-input"
                    type="radio"
                    name="flexRadioDefault"
                    id="code1"
                  />
                  <label class="form-check-label" for="code1"> Code </label>
                </div>
              </li>
              <!-- <li class="list-group-item">
    <div class="form-check">
  <input v-model="filterType" value="orgnization" class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1">
  <label class="form-check-label" for="flexRadioDefault1">
    
    Orgnization
  </label>
</div>
</li> -->
              <!--   <li class="list-group-item">
    <div class="form-check">
  <input v-model="filterType" value="dueDate" class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1">
  <label class="form-check-label" for="flexRadioDefault1">
  Due Date
  </label>
</div>
</li> -->
              <!-- <li class="list-group-item">
    <div class="form-check">
  <input v-model="filterType" value="completionDate" class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1">
  <label class="form-check-label" for="flexRadioDefault1">
  Completion Date
  </label>
</div>
  </li> -->
              <li class="list-group-item">
                <div class="form-check">
                  <input
                    @click="valueNullFilter()"
                    v-model="filterType"
                    value="name"
                    class="form-check-input"
                    type="radio"
                    name="flexRadioDefault"
                    id="name1"
                  />
                  <label class="form-check-label" for="name1"> Name </label>
                </div>
              </li>

              <li class="list-group-item">
                <div class="form-check">
                  <input
                    @click="valueNullFilter()"
                    v-model="filterType"
                    value="email"
                    class="form-check-input"
                    type="radio"
                    name="flexRadioDefault"
                    id="email1"
                  />
                  <label class="form-check-label" for="email1"> email </label>
                </div>
              </li>

              <li class="list-group-item">
                <div class="form-check">
                  <input
                    @click="valueNullFilter()"
                    v-model="filterType"
                    value="phone"
                    class="form-check-input"
                    type="radio"
                    name="flexRadioDefault"
                    id="phone1"
                  />
                  <label class="form-check-label" for="phone1"> phone </label>
                </div>
              </li>
              <li class="list-group-item">
                <div class="form-check">
                  <input
                    @click="valueNullFilter()"
                    v-model="filterType"
                    value="all"
                    class="form-check-input"
                    type="radio"
                    name="flexRadioDefault"
                    id="all1"
                  />
                  <label class="form-check-label" for="all1"> All </label>
                </div>
              </li>
            </ul>

            <div class="pt-1 pb-1">
              <!-- <label for="exampleFormControlInput1" class="form-label">Email address</label> -->
              <!-- <input type="date" v-if="filterType == 'date' || filterType == 'dueDate' || filterType == 'completionDate'" class="form-control datepicker" v-model="filterValue" id="exampleFormControlInput1" placeholder="Enter Value"> -->
              <input
                :type="filterType == 'phone' ? 'number' : 'text'"
                v-if="filterType != 'all'"
                class="form-control"
                v-model="filterValue"
                id="exampleFormControlInput1"
                placeholder="Enter Value"
              />
              <!-- <input type="text" v-if="filterType == 'client_id'" @change="findRecordeByPanId" class="form-control" v-model="filterValue" id="exampleFormControlInput1" placeholder="Enter Value"> -->
            </div>

            <button
              @click="loadretailers()"
              type="button"
              class="btn btn-success btn-sm"
            >
              Success
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
  <!-- Right Sidebar ends -->
</template>

<script>
import Permissions from "../../../mixins/permission";
import Banner from "../../../components/staff/comman/Banner.vue";
import Spinner from "../../../components/staff/comman/Spinner.vue";
import Form from "vform";
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";
// var _ = require('lodash');
import jQuery from "jquery";
let $ = jQuery;
export default {
  name: "StaffRetailers",
  mixins: [Permissions],
  components: {
    Banner,
    Spinner,
  },
  data() {
    return {
      filterValue: "",
      filterType: "",
      pagination: {
        current_page: 0,
        from: 0,
        to: 0,
        total: 0,
        last_page: 0,
        per_page: 0,
        first_page_url: null,
        last_page_url: null,
        next_page_url: null,
        prev_page_url: null,
        path: null,
      },
      per_page: 10,

      msgImgError: "",
      extensionkycAdharFront: "",
      extensionkycAdharBack: "",
      extensionkycPanPhoto: "",
      extensionkycPassbookPhoto: "",
      loading: false,
      retailers: [],
      retailer_id: null,
      retailerKycs: [],
      kycModalHeading: "",
      // retailer kyc form vars
      placeholderImg: this.$store.state.placeholderImg,
      retailerKyc_id: null,
      aadharNoForKyc: "",
      accountNoForKyc: "",
      panNoForKyc: "",
      photo: "",
      adharFrontImg: this.$store.state.placeholderImg,
      adharBackImg: this.$store.state.placeholderImg,
      panImg: this.$store.state.placeholderImg,
      passBookImg: this.$store.state.placeholderImg,
      retailerKycVal: "false",
      form: new Form({
        panNo: null,
        panPhoto: "",
        accountType: "",
        accountNo: null,
        passbookPhoto: "",
        aadharNo: null,
        aadharFrontPhoto: "",
        aadharBackPhoto: "",
        remark: "",
        errors: {},
      }),
      clientKycAddar: "false",
      retailerInformation: {},
      msgPanError: "",
      submitStatus: "false",
      panVarifyingStatus: "false",
      // permissions
      approveKyc: new Form({
        retailer_id: null,
        type: "kyc",
        status: "cancel",
        remark: "",
      }),
      aadharapproveKyc: new Form({
        retailer_id: null,
        type: "aadhar",
        status: "cancel",
        remark: "Aadhar Kyc",
      }),
      panapproveKyc: new Form({
        retailer_id: null,
        type: "pan",
        status: "cancel",
        remark: "",
      }),
      accountapproveKyc: new Form({
        retailer_id: null,
        type: "account",
        status: "cancel",
        remark: "",
      }),
      retailerKycAddar: "false",

      permissionCheck: {
        view: "",
        viewAny:true,
        kycView: "",
        kycCreate: "",
        kycUpdate: "",
        kycApprove: "",
      },
    };
  },
  methods: {
    valueNullFilter() {
      this.filterValue = "";
    },
    openWindow(url) {
      window.open(`${url}`);
    },
    permissionsCheker() {
      this.permissionCheck.view = this.loadPermissions("retailer-view");
      // this.permissionCheck.viewAny = this.loadPermissions("retailer-view-any");
      this.permissionCheck.kycView = this.loadPermissions("retailer-kyc-view");
      this.permissionCheck.kycCreate = this.loadPermissions("retailer-kyc-create");
      this.permissionCheck.kycUpdate = this.loadPermissions("retailer-kyc-update");
    },
    changePageNo(e) {
      this.per_page = e.target.value;
      this.loadretailers();
    },
    nameKeydown(e) {
      if (/^\W$/.test(e.key)) {
        $("#businessPanVeryfiedAdmin").html(
          "<small>special characters are not allowed</small>"
        );
        e.preventDefault();
      } else {
        $("#businessPanVeryfiedAdmin").html("<small></small>");
      }
    },
    businessPanVeryfied(event) {
      // var data = this.form.businessPan;
      var data = event.target.value;

      if (data.length == 10) {
        console.log(data.length);
        this.submitStatus = "false";
        $("#businessPanVeryfiedAdmin").html("<small></small>");

        this.msgPanError = "";
        this.loading = true;
        this.$axios
          .post(
            "staff/verifypan",
            { panNo: this.form.panNo },
            { headers: { Authorization: "Bearer " + localStorage.accessToken } }
          )
          .then((res) => {
            console.log(res.data.data.data);

            if (res.data.data.data.status != 'VALID') {
              this.submitStatus = "true";
              $("#businessPanVeryfiedAdmin").html(
                "<small>" + res.data.data.data.status + " Pan </small>"
              );
            }
            if (res.data.data.data.status == 'VALID') {
              this.submitStatus = "false";
              $("#businessPanVeryfiedAdmin").html("<small></small>");
              this.panVarifyingStatus = "true";

              toast.success("Pan Verified SuccesfulPan Verly", {
                autoClose: 1000,
              });
            } else {
              this.msgPanError = res.data.data.message;
            }
          })
          .finally(() => (this.loading = false))
          .catch((error) => {
            this.submitStatus = "true";
            this.$swal.fire({
              position: "top-center",
              icon: "error",
              title: "<small>" + error.response.data.error + "</small>",
              showConfirmButton: true,
              confirmButtonColor: "#00364f",
            });
          });
      } else {
        this.submitStatus = "true";
        $("#businessPanVeryfiedAdmin").html(
          "<small> Pan required minimum 10 Digits</small>"
        );
      }

      // this.panNo = ''
    },
    lenthheker(event, type) {
      var data = event.target.value;
      if (type == "clientKycAddar") {
        if (data.length != 12) {
          this.clientKycAddar = "true";

          $("#clientKycAddar").html("<small>Aadhar required minimum 12 letters</small>");
        }
        if (data.length == 12) {
          this.clientKycAddar = "false";
          $("#clientKycAddar").html("<small></small>");
        }
      }
      // done
    },
    // aadharKycApprovedSave() {
    //   var kyc;
    //   if (this.aadharapproveKyc.status == "approve") {
    //     kyc = "Approved";
    //   } else if (this.aadharapproveKyc.status == "reject") {
    //     kyc = "Rejected";
    //   } else if (this.aadharapproveKyc.status == "cancel") {
    //     kyc = "Cancelled";
    //   }
    //   this.loading = true;
    //   this.$axios
    //     .post("staff/approveretailerkyc", this.aadharapproveKyc, {
    //       headers: { Authorization: "Bearer " + localStorage.accessToken },
    //     })
    //     .then((res) => {
    //       console.log(res.data.data);
    //       this.$swal.fire({
    //         position: "top-end",
    //         icon: "success",
    //         title: "Aadhar Kyc " + kyc + " Succesfully",
    //         showConfirmButton: false,
    //         timer: 1500,
    //       });

    //       //    this.$router.push({ name: 'retailers' })
    //     })
    //     .finally(() => (this.loading = false));
    // },
    // accountKycApprovedSave() {
    //   var kyc;
    //   if (this.accountapproveKyc.status == "approve") {
    //     kyc = "Approved";
    //   } else if (this.accountapproveKyc.status == "reject") {
    //     kyc = "Rejected";
    //   } else if (this.accountapproveKyc.status == "cancel") {
    //     kyc = "Cancelled";
    //   }
    //   this.loading = true;
    //   this.$axios
    //     .post("staff/approveretailerkyc", this.accountapproveKyc, {
    //       headers: { Authorization: "Bearer " + localStorage.accessToken },
    //     })
    //     .then((res) => {
    //       console.log(res.data.data);
    //       this.$swal.fire({
    //         position: "top-end",
    //         icon: "success",
    //         title: "Bank Account Kyc " + kyc + " Succesfully",
    //         showConfirmButton: false,
    //         timer: 1500,
    //       });

    //       //    this.$router.push({ name: 'retailers' })
    //     })
    //     .finally(() => (this.loading = false));
    // },
    // panKycApprovedSave() {
    //   var kyc;
    //   if (this.panapproveKyc.status == "approve") {
    //     kyc = "Approved";
    //   } else if (this.panapproveKyc.status == "reject") {
    //     kyc = "Rejected";
    //   } else if (this.panapproveKyc.status == "cancel") {
    //     kyc = "Cancelled";
    //   }
    //   this.loading = true;
    //   this.$axios
    //     .post("staff/approveretailerkyc", this.panapproveKyc, {
    //       headers: { Authorization: "Bearer " + localStorage.accessToken },
    //     })
    //     .then((res) => {
    //       console.log(res.data.data);
    //       this.$swal.fire({
    //         position: "top-end",
    //         icon: "success",
    //         title: "Pan Kyc " + kyc + " Succesfully",
    //         showConfirmButton: false,
    //         timer: 1500,
    //       });

    //       //    this.$router.push({ name: 'retailers' })
    //     })
    //     .finally(() => (this.loading = false));
    // },
    // retailerKycApprovedSave() {
    //   var kyc;
    //   if (this.approveKyc.status == "approve") {
    //     kyc = "Approved";
    //   } else if (this.approveKyc.status == "reject") {
    //     kyc = "Rejected";
    //   } else if (this.approveKyc.status == "cancel") {
    //     kyc = "Cancelled";
    //   }
    //   this.loading = true;
    //   this.$axios
    //     .post("staff/approveretailerkyc", this.approveKyc, {
    //       headers: { Authorization: "Bearer " + localStorage.accessToken },
    //     })
    //     .then((res) => {
    //       console.log(res.data.data);
    //       this.$swal.fire({
    //         position: "top-end",
    //         icon: "success",
    //         title: "retailer Kyc " + kyc + " Succesfully",
    //         showConfirmButton: false,
    //         timer: 1500,
    //       });
    //       this.form = {};
    //       //    this.$router.push({ name: 'retailers' })
    //     })
    //     .finally(() => (this.loading = false));
    // },
    // retailerkycValueApprove(value) {
    //   // alert('this is the retailer id  retailerkycValueAsign' + value.id)
    //   console.log(value);
    //   this.approveKyc.retailer_id = value.id;
    //   this.accountapproveKyc.retailer_id = value.id;
    //   this.panapproveKyc.retailer_id = value.id;
    //   this.aadharapproveKyc.retailer_id = value.id;
    //   this.panNoForKyc = value.retailer_kyc.panNo;
    //   this.accountNoForKyc = value.retailer_kyc.accountNo;
    //   this.aadharNoForKyc = value.retailer_kyc.aadharNo;
    //   // this.form = value.retailer_kyc

    //   if (value.retailer_kyc.panPhoto != null) {
    //     this.panImg =
    //       this.$store.state.imgUrl +
    //       "/retailer/" +
    //       value.id +
    //       "/thumbs/" +
    //       value.retailer_kyc.panPhoto;
    //   } else {
    //     this.panImg = this.$store.state.placeholderImg;
    //   }

    //   if (value.retailer_kyc.passbookPhoto != null) {
    //     this.passBookImg =
    //       this.$store.state.imgUrl +
    //       "/retailer/" +
    //       value.id +
    //       "/thumbs/" +
    //       value.retailer_kyc.passbookPhoto;
    //   } else {
    //     this.passBookImg = this.$store.state.placeholderImg;
    //   }

    //   if (value.retailer_kyc.aadharFrontPhoto != null) {
    //     this.adharFrontImg =
    //       this.$store.state.imgUrl +
    //       "/retailer/" +
    //       value.id +
    //       "/thumbs/" +
    //       value.retailer_kyc.aadharFrontPhoto;
    //   } else {
    //     this.adharFrontImg = this.$store.state.placeholderImg;
    //   }

    //   if (value.retailer_kyc.aadharBackPhoto != null) {
    //     this.adharBackImg =
    //       this.$store.state.imgUrl +
    //       "/retailer/" +
    //       value.id +
    //       "/thumbs/" +
    //       value.retailer_kyc.aadharBackPhoto;
    //   } else {
    //     this.adharBackImg = this.$store.state.placeholderImg;
    //   }
    // },

    uploadAdharFront(e) {
      this.msgImgError = "";
      if (e.target.files["0"].size > 2 * (1024 * 1024)) {
        e.preventDefault();
        this.msgImgError = "Aadhar Front  File Size Too Big 2MB";
        return false;
      } else {
        var fileName;
        fileName = document.querySelector("#AdharFront").value;
        this.extensionkycAdharFront = fileName.split(".").pop();
        if (
          this.extensionkycAdharFront == "pdf" ||
          this.extensionkycAdharFront == "jpg" ||
          this.extensionkycAdharFront == "jpeg" ||
          this.extensionkycAdharFront == "png" ||
          this.extensionkycAdharFront == "PDF" ||
          this.extensionkycAdharFront == "JPEG" ||
          this.extensionkycAdharFront == "JPG" ||
          this.extensionkycAdharFront == "PNG"
        ) {
          this.adharFrontImg = URL.createObjectURL(e.target.files["0"]);
          this.form.aadharFrontPhoto = e.target.files["0"];
        } else {
          this.msgImgError = "File Type Must JPG,JPEG, PNG ,PDF";
        }
        if (this.extensionkycAdharFront == "pdf" || this.extensionkycAdharFront == "PDF") {
          this.adharFrontImg = "/assets/image/pdf.png";
        }
      }
    },
    uploadAdharBack(e) {
      this.msgImgError = "";
      if (e.target.files["0"].size > 2 * (1024 * 1024)) {
        e.preventDefault();
        this.msgImgError = "Aadhar Back File Size Too Big 2MB";
        return false;
      } else {
        var fileName;
        fileName = document.querySelector("#AdharBack").value;
        this.extensionkycAdharBack = fileName.split(".").pop();
        if (
           this.extensionkycAdharBack == "pdf" ||
          this.extensionkycAdharBack == "jpg" ||
          this.extensionkycAdharBack == "jpeg" ||
          this.extensionkycAdharBack == "png" ||
          this.extensionkycAdharBack == "PDF" ||
          this.extensionkycAdharBack == "JPEG" ||
          this.extensionkycAdharBack == "JPG" ||
          this.extensionkycAdharBack == "PNG"
        ) {
          this.adharBackImg = URL.createObjectURL(e.target.files["0"]);
          this.form.aadharBackPhoto = e.target.files["0"];
        } else {
          this.msgImgError = "File Type Must JPG,JPEG, PNG ,PDF";
        }
        if (this.extensionkycAdharBack == "pdf" || this.extensionkycAdharBack == "PDF") {
          this.adharBackImg = "/assets/image/pdf.png";
        }
      }
    },

    uploadPanPhoto(e) {
      this.msgImgError = "";
      if (e.target.files["0"].size > 2 * (1024 * 1024)) {
        e.preventDefault();
        this.msgImgError = "Pan  File Size Too Big 2MB";
        return false;
      } else {
        var fileName;
        fileName = document.querySelector("#panphoto").value;
        this.extensionkycPanPhoto = fileName.split(".").pop();
        if (
          this.extensionkycPanPhoto == "pdf" ||
          this.extensionkycPanPhoto == "jpg" ||
          this.extensionkycPanPhoto == "jpeg" ||
          this.extensionkycPanPhoto == "png" ||
          this.extensionkycPanPhoto == "PDF" ||
          this.extensionkycPanPhoto == "JPEG" ||
          this.extensionkycPanPhoto == "JPG" ||
          this.extensionkycPanPhoto == "PNG"
        ) {
          this.panImg = URL.createObjectURL(e.target.files["0"]);
          this.form.panPhoto = e.target.files["0"];
        } else {
          this.msgImgError = "File Type Must JPG,JPEG, PNG ,PDF";
        }
        if (this.extensionkycPanPhoto == "pdf" || this.extensionkycPanPhoto == "PDF") {
          this.panImg = "/assets/image/pdf.png";
        }
      }
    },
    retailerKycLoad() {
      this.loading = true;
      this.$axios
        .get("staff/retailerkyc", {
          headers: { Authorization: "Bearer " + localStorage.accessToken },
        })
        .then((res) => {
          this.retailerKycs = res.data.data.data;
          console.log(this.retailerKycs);
        })

        .finally(() => (this.loading = false));
    },
    retailerkycValueAsign(value) {
      this.panVarifyingStatus = 'false'
      this.form = {};
      this.msgImgError = "";
      this.adharFrontImg = this.$store.state.placeholderImg;
      this.adharBackImg = this.$store.state.placeholderImg;
      this.panImg = this.$store.state.placeholderImg;

      this.retailer_id = value.id;
      if (value.retailer_kyc == null) {
        this.kycModalHeading = "Retailer Kyc";
        this.retailerKycVal = "false";
      } else {
        this.kycModalHeading = "Retailer Kyc";

        this.form = value.retailer_kyc;
        if(this.form.panNo){
          this.panVarifyingStatus = 'true'
        }
        if (this.form.panPhoto != null) {
          if (
            this.$store.state.imgUrl +
            "/retailer/" +
            this.retailer_id +
            "/" +
            this.form.panPhoto
          ) {
            this.panImg =
              this.$store.state.imgUrl +
              "/retailer/" +
              this.retailer_id +
              "/" +
              this.form.panPhoto;
          }
          if (
            this.$store.state.imgUrl +
            "/retailer/" +
            this.retailer_id +
            "/thumbs/" +
            this.form.panPhoto
          ) {
            this.panImg =
              this.$store.state.imgUrl +
              "/retailer/" +
              this.retailer_id +
              "/thumbs/" +
              this.form.panPhoto;
          }

          this.extensionkycPanPhoto = this.panImg.split(".").pop();

          if (this.extensionkycPanPhoto == "pdf" || this.extensionkycPanPhoto == "PDF") {
            this.panImg = "/assets/image/pdf.png";
          }
        } else {
          this.panImg = this.$store.state.placeholderImg;
        }

        if (this.form.aadharFrontPhoto != null) {
          if (
            this.$store.state.imgUrl +
            "/retailer/" +
            this.retailer_id +
            "/" +
            this.form.aadharFrontPhoto
          ) {
            this.adharFrontImg =
              this.$store.state.imgUrl +
              "/retailer/" +
              this.retailer_id +
              "/" +
              this.form.aadharFrontPhoto;
          }
          if (
            this.$store.state.imgUrl +
            "/retailer/" +
            this.retailer_id +
            "/thumbs/" +
            this.form.aadharFrontPhoto
          ) {
            this.adharFrontImg =
              this.$store.state.imgUrl +
              "/retailer/" +
              this.retailer_id +
              "/thumbs/" +
              this.form.aadharFrontPhoto;
          }

          this.extensionkycAdharFront = this.adharFrontImg.split(".").pop();

          if (this.extensionkycAdharFront == "pdf" || this.extensionkycAdharFront == "PDF") {
            this.adharFrontImg = "/assets/image/pdf.png";
          }
        } else {
          this.adharFrontImg = this.$store.state.placeholderImg;
        }

        if (this.form.aadharBackPhoto != null) {
          if (
            this.$store.state.imgUrl +
            "/retailer/" +
            this.retailer_id +
            "/" +
            this.form.aadharBackPhoto
          ) {
            this.adharBackImg =
              this.$store.state.imgUrl +
              "/retailer/" +
              this.retailer_id +
              "/" +
              this.form.aadharBackPhoto;
          }
          if (
            this.$store.state.imgUrl +
            "/retailer/" +
            this.retailer_id +
            "/thumbs/" +
            this.form.aadharBackPhoto
          ) {
            this.adharBackImg =
              this.$store.state.imgUrl +
              "/retailer/" +
              this.retailer_id +
              "/thumbs/" +
              this.form.aadharBackPhoto;
          }

          this.extensionkycAdharBack = this.adharBackImg.split(".").pop();

          if (this.extensionkycAdharBack == "pdf" || this.extensionkycAdharBack == "PDF") {
            this.adharBackImg = "/assets/image/pdf.png";
          }
        } else {
          this.adharBackImg = this.$store.state.placeholderImg;
        }

        this.retailerKycVal = "true";
      }
    },

    retailerkycCreate() {
      if (this.clientKycAddar == "false" && this.submitStatus == 'false') {
        const formData = new FormData();
        const keys = Object.keys(this.form);
        for (let n = 0; n < keys.length; n += 1) {
          if (this.form[keys[n]] !== null) {
            formData.append(keys[n], this.form[keys[n]]);
          }
        }
        formData.append("retailer_id", this.retailer_id);
        console.log(formData);
        this.loading = true;
        this.$axios
          .post("staff/retailerkyc", formData, {
            headers: { Authorization: "Bearer " + localStorage.accessToken },
          })
          .then((res) => {
            console.log(res.data.data);

            toast.success("Kyc Created Succesfully", {
              autoClose: 1000,
            });
            this.form = {};
            this.form.errors = {};
            $("#kycModal").modal("hide");
            this.loadretailers();
            this.adharFrontImg = this.$store.state.placeholderImg;
            this.adharBackImg = this.$store.state.placeholderImg;
            this.panImg = this.$store.state.placeholderImg;
          })
          .finally(() => (this.loading = false))
          .catch((error) => {
            this.form.errors = error.response.data;
          })
         
      }
    },

    retailerkycUpdate() {
      if (this.clientKycAddar == "false" && this.submitStatus == 'false') {
        const formData = new FormData();
        const keys = Object.keys(this.form);
        for (let n = 0; n < keys.length; n += 1) {
          if (this.form[keys[n]] !== null) {
            formData.append(keys[n], this.form[keys[n]]);
          }
        }
        formData.append("retailer_id", this.retailer_id);
        this.loading = true;
        this.$axios
          .post(`staff/retailerkyc/${this.form.id}?_method=PUT`, formData, {
            headers: { Authorization: "Bearer " + localStorage.accessToken },
          })
          .then((res) => {
            console.log(res.data.data);

            toast.success("Kyc Updated Succesfully", {
              autoClose: 1000,
            });
            this.form = {};
            this.form.errors = {};
            $("#kycModal").modal("hide");
            this.loadretailers();
            this.adharFrontImg = this.$store.state.placeholderImg;
            this.adharBackImg = this.$store.state.placeholderImg;
            this.panImg = this.$store.state.placeholderImg;
          })
          .catch((error) => {
            this.form.errors = error.response.data;
          })
          .finally(() => (this.loading = false));
      }
    },
    retailerlogofinder(retailer) {
      var img;
      if (retailer.photo != null) {
        img =
          this.$store.state.imgUrl +
          "/retailer/" +
          retailer.id +
          "/thumbs/" +
          retailer.photo;
        return img;
      } else {
        img = this.$store.state.placeholderImg;
        return img;
      }
    },
    loadretailers(page = null, pg = null) {
      console.log(page);
      console.log(pg);
      this.loading = true;
      var pageUrl = "";
      if (this.pagination.last_page_url && pg == "last") {
        pageUrl = pageUrl + this.pagination.last_page_url;
      } else if (this.pagination.first_page_url && pg == "first") {
        pageUrl = pageUrl + this.pagination.first_page_url;
      } else if (this.pagination.prev_page_url && pg == "prev") {
        pageUrl = pageUrl + this.pagination.prev_page_url;
      } else if (this.pagination.next_page_url && pg == "next") {
        pageUrl = pageUrl + this.pagination.next_page_url;
      }

      if (pg == null) {
        if (this.filterType == "all") {
          this.filterValue = "";
          this.filterType = "";
        }

        if (this.filterValue != null && this.filterType != "all") {
          if (this.filterType == "name") {
            pageUrl += `staff/retailer?per_page=${this.per_page}&searchTerm=${this.filterValue}`;
          } else {
            pageUrl += `staff/retailer?per_page=${this.per_page}&${this.filterType}=${this.filterValue}`;
          }
        } else {
          pageUrl += `staff/retailer?per_page=${this.per_page}`;
        }
      } else {
        pageUrl += `&per_page=${this.pagination.per_page}`;
      }
      if (this.table_search) {
        pageUrl += `&searchTerm=${this.table_search}`;
      }
      if (page != null) {
        pageUrl += `&page=${page}`;
      }

      this.$axios
        .get(pageUrl, {
          headers: { Authorization: "Bearer " + localStorage.accessToken },
        })
        .then((res) => {
          console.log(res);
          this.retailers = res.data.data.data;
          this.pagination = res.data.data;
          this.filterValue = "";
        })
        .finally(() => (this.loading = false))
        .catch((error) => {
          if( error.response.data.error == 'Sorry! You do not have permission to access.'){
            this.permissionCheck.viewAny = false
          }
            this.$swal.fire({
              position: "top-center",
              icon: "error",
              title: "<small>" + error.response.data.error + "</small>",
              showConfirmButton: true,
              confirmButtonColor: "#00364f",
            });
          });
    },
    retailerkycValueApprove(value) {
      this.panImg = this.$store.state.placeholderImg;
      this.adharBackImg = this.$store.state.placeholderImg;
      this.adharFrontImg = this.$store.state.placeholderImg;

      this.approveKyc.status = value.retailer_kyc.kycStatus;
      this.approveKyc.remark = value.retailer_kyc.remark;

      this.panapproveKyc.status = value.retailer_kyc.panStatus;
      this.aadharapproveKyc.status = value.retailer_kyc.aadharStatus;

      this.approveKyc.retailer_id = value.id;
      this.accountapproveKyc.retailer_id = value.id;
      this.panapproveKyc.retailer_id = value.id;
      this.aadharapproveKyc.retailer_id = value.id;

      // this.form = value.admin_kyc

      if (value.retailer_kyc.panPhoto != null) {
        // this.panImg = this.$store.state.imgUrl + '/retailer/' + value.id + '/thumbs/' + value.retailer_kyc.panPhoto

        if (
          this.$store.state.imgUrl +
          "/retailer/" +
          value.id +
          "/thumbs/" +
          value.retailer_kyc.panPhoto
        ) {
          this.panImg =
            this.$store.state.imgUrl +
            "/retailer/" +
            value.id +
            "/thumbs/" +
            value.retailer_kyc.panPhoto;
        }
        if (
          this.$store.state.imgUrl +
          "/retailer/" +
          value.id +
          "/" +
          value.retailer_kyc.panPhoto
        ) {
          this.panImg =
            this.$store.state.imgUrl +
            "/retailer/" +
            value.id +
            "/" +
            value.retailer_kyc.panPhoto;
        }
        this.panImgPdf = this.panImg;
        this.extensionkycPanPhoto = this.panImg.split(".").pop();

        if (this.extensionkycPanPhoto == "pdf" || this.extensionkycPanPhoto == "PDF") {
          this.panImg = "/assets/image/pdf.png";
        }
      } else {
        this.panImg = this.$store.state.placeholderImg;
      }

      if (value.retailer_kyc.aadharFrontPhoto != null) {
        if (
          this.$store.state.imgUrl +
          "/retailer/" +
          value.id +
          "/thumbs/" +
          value.retailer_kyc.aadharFrontPhoto
        ) {
          this.adharFrontImg =
            this.$store.state.imgUrl +
            "/retailer/" +
            value.id +
            "/thumbs/" +
            value.retailer_kyc.aadharFrontPhoto;
        }

        if (
          this.$store.state.imgUrl +
          "/retailer/" +
          value.id +
          "/thumbs/" +
          value.retailer_kyc.aadharFrontPhoto
        ) {
          this.adharFrontImg =
            this.$store.state.imgUrl +
            "/retailer/" +
            value.id +
            "/" +
            value.retailer_kyc.aadharFrontPhoto;
        }

        this.adharFrontImgPdf = this.adharFrontImg;
        this.extensionkycAdharFront = this.adharFrontImg.split(".").pop();

        if (this.extensionkycAdharFront == "pdf" || this.extensionkycAdharFront == "PDF") {
          this.adharFrontImg = "/assets/image/pdf.png";
        }
      } else {
        this.adharFrontImg = this.$store.state.placeholderImg;
      }

      if (value.retailer_kyc.aadharBackPhoto != null) {
        if (
          this.$store.state.imgUrl +
          "/retailer/" +
          value.id +
          "/thumbs/" +
          value.retailer_kyc.aadharBackPhoto
        ) {
          this.adharBackImg =
            this.$store.state.imgUrl +
            "/retailer/" +
            value.id +
            "/thumbs/" +
            value.retailer_kyc.aadharBackPhoto;
        }
        if (
          this.$store.state.imgUrl +
          "/retailer/" +
          value.id +
          "/" +
          value.retailer_kyc.aadharBackPhoto
        ) {
          this.adharBackImg =
            this.$store.state.imgUrl +
            "/retailer/" +
            value.id +
            "/" +
            value.retailer_kyc.aadharBackPhoto;
        }

        this.adharBackImgPdf = this.adharBackImg;
        this.extensionkycAdharBack = this.adharBackImg.split(".").pop();

        if (this.extensionkycAdharBack == "pdf" || this.extensionkycAdharBack == "PDF") {
          this.adharBackImg = "/assets/image/pdf.png";
        }
      } else {
        this.adharBackImg = this.$store.state.placeholderImg;
      }
    },
    retailerKycApprovedSave() {
      var kyc;
      // alert(this.approveKyc.status)
      
      if (this.approveKyc.status == "approve") {
        kyc = "Approved";
      } else if (this.approveKyc.status == "reject") {
        kyc = "Rejected";
      } else if (this.approveKyc.status == "cancel") {
        kyc = "Cancelled";
      }

      if(this.approveKyc.status == "approve" || this.approveKyc.status == "reject" || this.approveKyc.status == "cancel"){
        this.loading = true;
      this.$axios
        .post("staff/approveretailerkyc", this.approveKyc, {
          headers: { Authorization: "Bearer " + localStorage.accessToken },
        })
        .then((res) => {
          res;
          toast.success("Professional Kyc " + kyc + " Succesfully", {
            autoClose: 1000,
          });
          this.form = {};
          //    this.$router.push({ name: 'Admins' })
          this.loadretailers();
          $("#approoveKycModal").modal("hide");
        })
        .finally(() => (this.loading = false))
        .catch((error) => {
            // this.submitStatus = "true";
            this.$swal.fire({
              position: "top-center",
              icon: "error",
              title: "<small>" + error.response.data.error + "</small>",
              showConfirmButton: true,
              confirmButtonColor: "#00364f",
            });
          });
      }
     
    },
    aadharKycApprovedSave() {
      var kyc;
      if (this.aadharapproveKyc.status == "approve") {
        kyc = "Approved";
      } else if (this.aadharapproveKyc.status == "reject") {
        kyc = "Rejected";
      } else if (this.aadharapproveKyc.status == "cancel") {
        kyc = "Cancelled";
      }
      this.loading = true;
      this.$axios
        .post("staff/approveretailerkyc", this.aadharapproveKyc, {
          headers: { Authorization: "Bearer " + localStorage.accessToken },
        })
        .then((res) => {
          res;
          toast.success("Aadhar Kyc " + kyc + " Succesfully", {
            autoClose: 1000,
          });
        })
        .finally(() => (this.loading = false))
        .catch((error) => {
            // this.submitStatus = "true";
            this.$swal.fire({
              position: "top-center",
              icon: "error",
              title: "<small>" + error.response.data.error + "</small>",
              showConfirmButton: true,
              confirmButtonColor: "#00364f",
            });
          });
    },

    panKycApprovedSave() {
      var kyc;
      if (this.panapproveKyc.status == "approve") {
        kyc = "Approved";
      } else if (this.panapproveKyc.status == "reject") {
        kyc = "Rejected";
      } else if (this.panapproveKyc.status == "cancel") {
        kyc = "Cancelled";
      }
      this.loading = true;
      this.$axios
        .post("staff/approveretailerkyc", this.panapproveKyc, {
          headers: { Authorization: "Bearer " + localStorage.accessToken },
        })
        .then((res) => {
          res;

          toast
            .success("Pan Kyc " + kyc + " Succesfully", {
              autoClose: 1000,
            })
           

          //    this.$router.push({ name: 'Admins' })
        })
        .finally(() => (this.loading = false))
        .catch((error) => {
            // this.submitStatus = "true";
            this.$swal.fire({
              position: "top-center",
              icon: "error",
              title: "<small>" + error.response.data.error + "</small>",
              showConfirmButton: true,
              confirmButtonColor: "#00364f",
            });
          });

    },
    loadPageTitle(){
        document.title =this.$store.state.staffPageTitles.retailer
      }
  },
  created() {
    if (
      this.$route.meta.redirectIfLoggedIn == "true" &&
      localStorage.getItem("accessToken") == null
    ) {
      this.$router.push({ name: "Login" });
    } else {
      this.loadretailers();
      this.permissionsCheker();
      this.loadPageTitle()
    }
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap");

.custom-card {
  border-radius: 10px;
  box-shadow: 0px 0px 5px 0px rgba(206, 205, 205, 0.555);
  background-color: white;
}

.h4-heading {
  color: red;
  font-size: 1.2vw;
}

.font-heading {
  font-family: "Poppins", sans-serif;
  font-size: 3rem;
  color: rgb(216, 209, 209);
}

.text {
  font-family: "Poppins", sans-serif;
  color: #00364f;
}

.font {
  font-family: "Poppins", sans-serif;
}

.btns {
  background-color: #00364f;
  font-size: 12px;
  padding: 8px 8px 8px 8px;
}

.radius {
  border-radius: 23px;
}

.form-check-input:checked {
  background-color: #f21300;
  border-color: #f21300;
}

.tr-head {
  font-size: 11pt;
  margin-left: 25px;
  border-radius: 26px;
  background-color: #cdcdcd;
}
.page-item.active .page-link {
  z-index: 3;
  border-radius: 5rem;
  background-color: #00364f;
  color: #fff !important;
  font-weight: 600;
}
.table-scroll {
  overflow-y: auto;
}
.table.table-sm th,
.table.table-sm td {
  padding: 0.2rem 0.5rem rem;
  /* font-weight: 200; */
}

.form-check-input:checked {
  background-color: #ffffff;
  border-color: #f21000;
  border-width: 5px;
}
.form-check-input:not(:disabled):checked {
  box-shadow: 0 2px 4px 0 rgb(225 0 0 / 28%);
}





.cus-p-1 {
  padding: 5px 5px 5px 5px;
}

@media only screen and (min-width: 600px) {
  .justify-content-center {
    justify-content: end !important;
  }
}

.card-footer {
  padding: 0px 10px 0px 10px;
}
</style>
